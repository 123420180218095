import React, { createContext, useState, useReducer, useEffect, useCallback, useContext } from 'react'
import { useOfferUser } from './offerUserHooks'
import * as api from './userApi'

const context = createContext()

const reducer = (state, action) => {
    switch (action.type) {
        case 'setResult':
            return action.result
        case 'replace':
            return state.map(v => {
                if (v.offerId === action.item.offerId) {
                    return action.item
                }
                return v
            })
        default:
            return state
    }
}

export const Provider = props => {
    const { children } = props
    const {authCode} = useOfferUser()
    const [offersResult, dispatch] = useReducer(reducer, initialOffersResult)
    const {items: offers} = offersResult
    const {total: offersTotal} = offersResult
    const [fetching, setFetching] = useState(true)
    const [searchOpts, setSearchOpts] = useState(initialSearchOpts)
    const [selectedItem, setSelectedItem] = useState(null)

    const fetchOffersAsync = useCallback(async () => {
        try {
            console.log('fetchOffersAsync', {searchOpts})
            setFetching(true)
            const { ok, result } = await api.getOffers(authCode, searchOpts)
            if (ok) {
                dispatch({ type: 'setResult', result })
            }
        } catch (error) {
            console.log({ error })
        } finally {
            setFetching(false)
        }
    }, [authCode, searchOpts])
    useEffect(() => {
        fetchOffersAsync()
    }, [fetchOffersAsync])
    const offerById = useCallback(offerId => {
        if (fetching) {
            return null
        }
        return offers.find(o => o.offerId === offerId)
    }, [offers, fetching])
    const setPage = useCallback(page => {
        setSearchOpts(cur => ({
            ...cur,
            page,
        }))
    }, [])
    const setOrderBy = useCallback(orderBy => {
        setSearchOpts(cur => ({
            ...cur,
            orderBy,
            page: 0,
        }))
    }, [])
    const ctx = {
        isLoading: fetching,
        offers,
        offerById,
        offersTotal,
        searchOpts,
        setPage,
        selectedItem,
        setSelectedItem,
        setOrderBy,
    }
    return (
        <context.Provider value={ctx}>
            {children}
        </context.Provider>
    )
}

export const useOffers = () => {
    return useContext(context)
}

const initialOffersResult = {
    items: [],
    total: 0,
}

const initialSearchOpts = {
    typ: "std",
    page: 1,
    perPage: 20,
    companyName: "",
    orderBy: 'createdAt',
}
