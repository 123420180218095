import React, { useEffect } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Provider, useOfferUser } from './offerUserHooks'
import { Provider as OfferFormProvider, useOfferForm } from './offerForm'
import OfferPage1 from './OfferPage1'
import OfferPage2 from './OfferPage2'
import OfferPage3 from './OfferPage3'
import OfferPage4 from './OfferPage4'
import OfferTop from './OfferTop'
import { CalenderProvider } from './calender'
import OfferThanks from './OfferThanks'
import OffersTable from './OffersTable'
import Offers from './Offers'

const FormApp = props => {
    const { authCode } = useParams()
    return (
        <Provider authCode={authCode}>
            <PageContainer />
        </Provider>
    )
}

const Header = props => {
    const { user } = useOfferUser()
    return (
        <header>
            <div className="contents1000 clearfix">
                <img src="https://hoshoexpress.co.jp/wp-content/themes/wptheme/images/mainLogo.png" alt="株式会社ホーショー" />
                <div className="rightBox">
                    <h1>株式会社ホーショー配車予約フォーム</h1>
                    <span className="userName">{user.companyName}様</span>
                </div>
            </div>
        </header>
    )
}

const Footer = props => {
    return (
        <footer>
            <a href="https://hoshoexpress.co.jp/" title="株式会社ホーショー トップページ"><img src="https://hoshoexpress.co.jp/wp-content/uploads/2020/08/footerLogo2.png" alt="株式会社ホーショー" className="footLogo" /></a><br />
            <div className="copy">Copyright © 2020 Hosho Inc.</div>
        </footer>
    )
}

const PageContainer = props => {
    const { isLoading } = useOfferUser()
    if (isLoading) {
        return <div>...</div>
    }
    return (
        <React.Fragment>
            <Header />
            <Switch>
                <Route path="/offer/ac=:authCode" exact component={OfferTop} />
                <Route path="/offer/ac=:authCode/form" exact component={OfferFormContainer} />
                <Route path="/offer/ac=:authCode/thanks" exact component={OfferThanks} />
                <Route path="/offer/ac=:authCode/offers" exact component={Offers} />
            </Switch>
            <Footer />
        </React.Fragment>
    )
}

export default FormApp

function onUnload(e) {
    const msg = ""
    e.returnValue = msg
    return msg
}

const OfferFormContainer = props => {
    useEffect(() => {
        window.addEventListener('beforeunload', onUnload)
        return () => {
            window.removeEventListener('beforeunload', onUnload)
        }
    }, [])
    return (
        <CalenderProvider>
            <OfferFormProvider>
                <OfferForm />
            </OfferFormProvider>
        </CalenderProvider>
    )
}


const OfferForm = props => {
    const { page } = useOfferForm()
    return (
        <>
            {page === 1 && <OfferPage1 />}
            {page === 2 && <OfferPage2 />}
            {page === 3 && <OfferPage3 />}
            {page === 4 && <OfferPage4 />}
        </>
    )
}