import React, { useCallback, useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'

import * as api from './adminApi'

const NUM_OFFER_GRADES = 4

const dateformat = require('dateformat')

const weeks = ['日', '月', '火', '水', '木', '金', '土']

const datestr = (year, month, day) => {
    const d = new Date(year, month - 1, day, 0, 0, 0, 0)
    const s1 = dateformat(d, "yyyy/mm/dd")
    const s2 = weeks[d.getDay()]
    return `${s1}(${s2})`
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'setItems':
            return {
                ...state,
                items: action.items
            }
        case 'changeItemStatus':
            return {
                ...state,
                items: state.items.map(v => {
                    if (v !== action.item) {
                        return v
                    }
                    return {
                        ...v,
                        status: action.status,
                    }
                })
            }
        case 'selectItem':
            return {
                ...state,
                selectedItem: action.item
            }
        case 'unselectItem':
            if (state.selectedItem !== action.item) {
                return state
            }
            return {
                ...state,
                selectedItem: null,
            }
        default:
            return state
    }
}

const initialState = {
    items: null,
    selectedItem: null,
}

const AdminCalender = props => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { items, selectedItem } = state
    const fetchCalender = useCallback(async () => {
        const { ok, items: calItems } = await api.getCalender()
        if (!ok) {
            return
        }
        dispatch({ type: 'setItems', items: calItems })
    }, [])
    useEffect(() => {
        fetchCalender()
    }, [fetchCalender])

    const handleClickItem = useCallback(item => evt => {
        evt.preventDefault()
        if (item === selectedItem) {
            return
        }
        dispatch({ type: 'selectItem', item })
        return false
    }, [selectedItem])
    const changeItemStatusAsync = useCallback(async (item, status) => {
        const {ok, item: nextItem} = await api.changeCalenderStatus(item.year, item.month, item.day, item.section, item.grade, status)
        if (!ok) {
            return
        }
        dispatch({type: 'changeItemStatus', item, status: nextItem.status})
    }, [])
    const handleChangeItem = useCallback(item => async evt => {
        changeItemStatusAsync(item, evt.target.value)
    }, [changeItemStatusAsync])

    const handleBlurSelect = useCallback(item => evt => {
        dispatch({type: 'unselectItem', item})
    }, [])

    const renderItem = useCallback(item => {
        if (item === selectedItem) {
            return (
                <td>
                    <MySelect value={item.status} onBlur={handleBlurSelect(item)} onChange={handleChangeItem(item)}>
                        <option value="enable">〇</option>
                        <option value="maybe">△</option>
                        <option value="unable">×</option>
                    </MySelect>
                </td>
            )
        }
        switch (item.status) {
            case 'enable':
                return <td onClick={handleClickItem(item)}>〇</td>
            case 'maybe':
                return <td onClick={handleClickItem(item)}>△</td>
            case 'unable':
                return <td onClick={handleClickItem(item)}>×</td>
            default:
                return <td></td>
        }
    }, [selectedItem, handleBlurSelect, handleClickItem, handleChangeItem])

    if (!items) {
        return <div>...</div>
    }
    console.log({ items })
    /*
    const rowItems = []
    for (let i = 0; i < items.length; i += 4) {
        const item = items.slice(i, (i+1)*4)
        dayItems.push(item)
    }
    console.log({dayItems})
    */



    function* genRows() {
        for (let i = 0; i < items.length; i += 2*(NUM_OFFER_GRADES)) {
            const dayItem = items.slice(i, i + 2*(NUM_OFFER_GRADES))
            yield (
                <tr key={`${i}/1`}>
                    <th rowspan="2">{datestr(dayItem[0].year, dayItem[0].month, dayItem[0].day)}</th>
                    <th className="">午前</th>
                    {renderItem(dayItem[0])}
                    {renderItem(dayItem[1])}
                    {renderItem(dayItem[2])}
                    {renderItem(dayItem[3])}
                </tr>
            )
            yield (
                <tr key={`${i}/2`}>
                    <th className="">午後</th>
                    {renderItem(dayItem[4])}
                    {renderItem(dayItem[5])}
                    {renderItem(dayItem[6])}
                    {renderItem(dayItem[7])}
                </tr>
            )
        }
    }

    const rows = [...genRows()]

    return (
        <section className="contents">
            <div className="contents1000">
                <h2 className="adminTitle">カレンダー管理</h2>
                <div className="slideTableBox">
                    <table className="calenderTable">
                        <thead>
                            <tr>
                                <th>日にち</th>
                                <th>時間</th>
                                <th>3トンユニック車</th>
                                <th>7トンユニック車</th>
                                <th>15トンユニック車</th>
                                <th>15トン平車</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>


                <Link to="/admin" className="backBtn">管理画面トップに戻る</Link>
            </div>
        </section>
    )
}

export default AdminCalender

const MySelect = props => {
    const {children, ...otherProps} = props
    return  (
        <select {...otherProps}>
            {children}
        </select>
    )
}