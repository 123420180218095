import React, { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import OfferProgress from './OfferProgress'
import { useOfferUser } from './offerUserHooks'

const OfferThanks = props => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { authCode } = useOfferUser()
    return (
        <section className="contents">
            <div className="contents1000">
                <OfferProgress progress={5} />
                <p className="mainComment">
                    この度は株式会社ホーショーの配車予約フォームをご利用頂き、有難うございました。 <br />
                    配車の申し込みが完了いたしました。 <br />
<br />
                    早急に担当者が内容を確認させて頂きます。 <br />
                    配車のご予約の確定時には、改めてメールをお送りさせて頂きます。 <br />
                    もう少々お待ちくださいませ。<br />
<br />
                    なお、お申込み内容についてご相談が必要な場合は、担当者より折り返しお電話させて頂きます。
                </p>

                <Link to={`/offer/ac=${authCode}/form`} className="defaultBtn selectBtn">続けて配車を申し込む</Link>
                <Link to={`/offer/ac=${authCode}`} className="backBtn">トップに戻る</Link>
            </div>
        </section>
    )
}

export default OfferThanks