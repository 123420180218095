import React, { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import * as api from './adminApi'

const context = createContext()

const reducer = (state, action) => {
    switch (action.type) {
        case 'loginFail':
            return {
                ...state,
                fetching: false,
                isLoggedIn: false,
            }
        case 'loginSuccess':
            return {
                ...state,
                fetching: false,
                isLoggedIn: true,
            }
        default:
            return state
    }
}
const initialState = {
    session: null,
    fetching: true,
    isLoggedIn: false,
}

export const AdminAuthProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const fetchSession = useCallback(async () => {
        const { ok, item } = await api.getCurrentSession()
        if (!ok) {
            console.log('logined failed', { ok, item })
            dispatch({ type: 'loginFail' })
            return
        }
        dispatch({ type: 'loginSuccess', session: item })
    }, [])
    useEffect(() => {
        fetchSession()
    }, [fetchSession])
    const {session, fetching, isLoggedIn} = state
    useEffect(() => {
        if (!fetching && !isLoggedIn) {
            window.location.href = "/admin/login"
            return
        }
    }, [fetching, isLoggedIn])
    if (fetching || !isLoggedIn) {
        return <div>...</div>
    }
    const ctx = {
        session, fetching, isLoggedIn,
    }
    return (
        <context.Provider value={ctx}>
            {props.children}
        </context.Provider>
    )
}

export const useAdminAuth = () => {
    return useContext(context)
}
