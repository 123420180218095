import React, { useCallback, useLayoutEffect } from 'react'
import { useOfferForm } from './offerForm'
import { Link, useHistory } from 'react-router-dom'
import OfferProgress from './OfferProgress'
import { useOfferUser } from './offerUserHooks'
import * as api from './userApi'

const dateformat = require('dateformat')

export const formats = {
    gozen: '午前',
    gogo: '午後',
    syujitu: '終日チャーター',
    mitei: '未定',
    '3t': '3トンユニック車',
    '7t': '7トンユニック車',
    '13t': '15トンユニック車',
    '13th': '15平車',
    nouhin: '納品',
    hikitori: '引き取り',
    nouhin_hikitori: '納品/引き取り',
    maezumi_ari: 'あり',
    maezumi_nashi: 'なし',
    maezumi_mitei: '未定',
}


const OfferPage4 = props => {
    const history = useHistory()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { offer, setPage } = useOfferForm()
    const { authCode } = useOfferUser()

    const postOfferAsync = useCallback(async offer => {
        const { ok } = await api.postOffer(authCode, offer)
        if (ok) {
            history.replace(`/offer/ac=${authCode}/thanks`)
        }
    }, [authCode, history])

    const handleClickSubmit = useCallback(evt => {
        evt.preventDefault()
        postOfferAsync(offer)
        return false
    }, [postOfferAsync, offer])

    const handleClickBack = useCallback(evt => {
        evt.preventDefault()
        setPage(3)
        return false
    }, [setPage])

    return (
        <section className="contents">
            <div className="contents1000">
                <OfferProgress progress={4} />

                <p className="mainComment">
                下記の内容で宜しければ「お申込み確定」をクリックしてください。
                </p>

                <div className="formBlock kakunin">
                    <div className="form clearfix">
                        <div className="leftBox">配車日</div>
                        <div className="rightBox">{offer.year}年{offer.month}月{offer.day}日 {formats[offer.section]}</div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">車体</div>
                        <div className="rightBox">{formats[offer.grade]}</div>
                    </div>
                    {['gozen', 'gogo'].includes(offer.section) && (
                        <div className="form clearfix">
                            <div className="leftBox">着指定時間</div>
                            <div className="rightBox">{dateformat(new Date(2020, 0, 1, offer.startHour, offer.startMinute, 0, 0), "HH:MM")}</div>
                        </div>
                    )}
                    {offer.section !== 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">業務内容</div>
                            <div className="rightBox">{formats[offer.task]}</div>
                        </div>
                    )}
                    {offer.section === 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">前積み</div>
                            <div className="rightBox">{formats[offer.task]}</div>
                        </div>
                    )}
                    {offer.section !== 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">場所</div>
                            <div className="rightBox">{offer.basho}</div>
                        </div>
                    )}
                    {offer.section !== 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">備考</div>
                            <div className="rightBox">{offer.bikou}</div>
                        </div>
                    )}
                    {offer.section === 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">業務内容</div>
                            <div className="rightBox">{offer.bikou}</div>
                        </div>
                    )}
                    <form onSubmit={handleClickSubmit}>
                        <input type="submit" className="defaultBtn selectBtn" value="お申込み確定" />
                    </form>
                </div>
                <Link onClick={handleClickBack} className="backBtn">詳細情報の入力に戻る</Link>
            </div>
        </section>
    )
}

export default OfferPage4
