import React from 'react';

import {BrowserRouter, Switch, Route} from 'react-router-dom'
import FormApp from './FormApp';
import AdminApp from './AdminApp';
import AdminLogin from './AdminLogin';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/offer/ac=:authCode" component={FormApp} />
        <Route path="/admin/login" component={AdminLogin} />
        <Route path="/admin" component={AdminApp} />
        <Route path="/" render={() => <div>default</div>} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
