import React from 'react'
import { Link } from 'react-router-dom'

const AdminHome = props => {
    return (
        <section className="contents">
            <div className="contents1000">

                <h2 className="adminTitle">管理画面トップ</h2>

                <Link to="/admin/offers" className="defaultBtn mainBtn">申し込みの管理</Link>
                <Link to="/admin/calender" className="defaultBtn mainBtn">カレンダー管理</Link>
                <Link to="/admin/users" className="defaultBtn mainBtn">顧客管理</Link>
            </div>
        </section>
    )
}

export default AdminHome