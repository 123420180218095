import React, { useCallback, useEffect, useState } from 'react'
import { useOfferUser } from './offerUserHooks'
import * as api from './userApi'

const dateformat = require('dateformat')

const OfferTop = props => {
    const { authCode } = useOfferUser()
    const [shakenshos, setShakenshos] = useState()
    const fetchShakenshoAsync = useCallback(async () => {
        const items = {}
        {
            const { ok, item } = await api.getShakensho("information")
            if (!ok) {
                return
            }
            items.information = item
        }
        {
            const { ok, item } = await api.getShakensho("unicnenji")
            if (!ok) {
                return
            }
            items.unicnenji = item
        }
        {
            const { ok, item } = await api.getShakensho("unicgetsuji")
            if (!ok) {
                return
            }
            items.unicgetsuji = item
        }
        setShakenshos(items)
    }, [])
    useEffect(() => {
        fetchShakenshoAsync()
    }, [fetchShakenshoAsync])
    return (
        <section className="contents">
            <div className="contents1000">
                <p className="mainComment">
                    この度は株式会社ホーショーの配車予約フォームをご利用頂き、有難うございます。<br />
配車をご希望の方は、下記の申し込みボタンよりお申込みをお願い致します。 <br />
                    <br />
また弊社の各種点検書類をご入用の方は、下記ボタンよりPDFのダウンロードをお願い致します。

		        </p>

                <a href={`/offer/ac=${authCode}/form`} className="defaultBtn mainBtn">配車のお申込みはこちら</a>
                <a href={`/offer/ac=${authCode}/offers`} className="defaultBtn mainBtn">予約を確認する</a>
                {shakenshos && (
                    <React.Fragment>
                        <a href="https://hoshoexpress.co.jp/vic/" class="defaultBtn subBtn" target="_blank"><span class="subTitle2">車検証のご請求</span></a>
                        {shakenshos.unicnenji.url && (
                            <a href={shakenshos.unicnenji.url} className="defaultBtn subBtn" target="_blank">
                                <span className="subTitle">ユニック年次点検簿ダウンロード</span><br />
                                <span className="subMini">{dateformat(shakenshos.unicnenji.modifiedAt, "yyyy年mm月dd日")}更新</span>
                            </a>
                        )}
                        {shakenshos.unicgetsuji.url && (
                            <a href={shakenshos.unicgetsuji.url} className="defaultBtn subBtn" target="_blank">
                                <span className="subTitle">ユニック月例点検簿ダウンロード</span><br />
                                <span className="subMini">{dateformat(shakenshos.unicgetsuji.modifiedAt, "yyyy年mm月dd日")}更新</span>
                            </a>
                        )}
                    </React.Fragment>
                )}
                {!shakenshos && (
                    <span className="defaultBtn subBtn">
                        <span className="subTitle">車検証情報</span><br />
                        <span className="subMini"></span>
                    </span>
                )}
            </div>
        </section>
    )
}

export default OfferTop

