import React, { createContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import AdminUsers from './AdminUsers'
import AdminHome from './AdminHome'
import AdminOffers from './AdminOffers'
import AdminCalender from './AdminCalender'
import { AdminAuthProvider } from './adminAuth'

const AdminApp = props => {
    return (
        <AdminAuthProvider>
            <Switch>
                <Route path="/admin" exact component={AdminHome} />
                <Route path="/admin/users" component={AdminUsers} />
                <Route path="/admin/offers" component={AdminOffers} />
                <Route path="/admin/calender" component={AdminCalender} />
            </Switch>
        </AdminAuthProvider>
    )
}

export default AdminApp
