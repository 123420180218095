import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useForm } from './form'
import { useAdminUsers } from './adminUsersHooks'
import { Link, useHistory, useParams } from 'react-router-dom'

const initialUser = {
    companyName: '',
    name: '',
    email: '',
    tel: '',
    memo: '',
}

const deletedUser = {
    companyName: '',
    name: '',
    email: '',
    tel: '',
    memo: '',
    status: 'inactive',
}

const AdminUsersNew = props => {
    const history = useHistory()
    const { isLoading, addUser, saveUser, deleteUser } = useAdminUsers()
    const iniUser = useInitialUser()
    console.log({ iniUser })
    const [user, setUser] = useState(iniUser)
    useEffect(() => {
        if (iniUser === deletedUser) {
            history.push("/admin/users")
        }
    }, [iniUser, history])
    useEffect(() => {
        setUser(iniUser)
    }, [iniUser])
    const { inputProps } = useForm(user, {
        mapItemToFormValues: item => ({
            ...item,
        }),
        onChange: (name, value, values) => {
            setUser({
                ...user,
                [name]: value,
            })
        }
    })
    const handleSubmitForm = useCallback(async evt => {
        evt.preventDefault()
        if (user.userId) {
            const { ok } = await saveUser(user)
            if (ok) {
                alert('保存しました')
            }
        } else {
            const { ok, item } = await addUser(user)
            if (ok) {
                alert('保存しました')
                history.replace(`/admin/users/${item.userId}`)
            }
        }
        return false
    }, [user, addUser, saveUser, history])
    const handleClickDeleteButton = useCallback(async evt => {
        evt.preventDefault()
        if (!user.userId) {
            return
        }
        if (!window.confirm('削除してもよろしいですか？')) {
            return
        }
        const { ok } = await deleteUser(user)
        if (!ok) {
            return
        }
        alert('削除しました')
        return false
    }, [user, deleteUser])
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    if (isLoading) {
        return <div>...</div>
    }
    return (
        <section className="contents">
            <div className="contents1000">

                <h2 className="adminTitle">新規顧客登録</h2>




                <form className="formBlock admin" onSubmit={handleSubmitForm}>

                    <div className="form clearfix">
                        <div className="leftBox">会社名</div>
                        <div className="rightBox">
                            <input className="" {...inputProps('companyName', 'text')} />
                        </div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">担当者名</div>
                        <div className="rightBox">
                            <input className="" {...inputProps('name', 'text')} />
                        </div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">電話番号</div>
                        <div className="rightBox">
                            <input className="" {...inputProps('tel', 'text')} />
                        </div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">メールアドレス</div>
                        <div className="rightBox">
                            <input className="" {...inputProps('email', 'text')} />
                        </div>
                    </div>
                    {user.authCode && (
                        <div className="form clearfix">
                            <div className="leftBox">申し込みページ</div>
                            <div className="rightBox">
                                <textarea value={`https://yoyaku.hoshoexpress.co.jp/offer/ac=${user.authCode}`} />
                            </div>
                        </div>
                    )}
                    {user.userId && (
                        <button className="defaultBtn selectBtn" disabled={user.status !== "active"} onClick={handleClickDeleteButton}>削除</button>
                    )}
                    <input type="submit" className="defaultBtn selectBtn" value="保存" />
                </form>
                <Link to="/admin" className="backBtn">管理画面トップに戻る</Link>
            </div>
        </section>
    )
}

export default AdminUsersNew

const useInitialUser = () => {
    const params = useParams()
    const { userById } = useAdminUsers()
    var user
    if (params.userId) {
        user = userById(params.userId)
        console.log({ params, user })
        if (!user) {
            return deletedUser
        }
    }
    if (user) {
        return user
    }
    return initialUser
}
