import React, { useCallback } from 'react'
import { useAdminUsers } from './adminUsersHooks'
import { Link, useHistory } from 'react-router-dom'

const AdminUsersTable = props => {
    const history = useHistory()
    const { isLoading, users } = useAdminUsers()
    const handleClickRow = useCallback(item => evt => {
        evt.preventDefault()
        history.push(`/admin/users/${item.userId}`)
        return false
    }, [history])
    if (isLoading) {
        return <div>...</div>
    }
    return (
        <React.Fragment>
            <section className="contents">
                <div className="contents1000">

                    <h2 className="adminTitle">顧客一覧</h2>

                    <div className="slideTableBox">
                        <table className="adminTable">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>会社名</th>
                                    <th>担当者名</th>
                                    <th>電話番号</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(item => (
                                    <tr key={item.userId} onClick={handleClickRow(item)}>
                                        <td>{item.userId}</td>
                                        <td>{item.companyName}</td>
                                        <td>{item.name}</td>
                                        <td>{item.tel}</td>
                                        <td>{item.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Link to="/admin/users/new" className="defaultBtn mainBtn">新規顧客を登録する</Link>
                    <Link to="/admin" className="backBtn">管理画面トップに戻る</Link>
                </div>
            </section>
        </React.Fragment>
    )
}

export default AdminUsersTable