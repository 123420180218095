import React, { useMemo, useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { useAdminOffers } from './adminOffersHook'
import { Link, useParams } from 'react-router-dom'
import { useForm } from './form'

const dateformat = require('dateformat')
const stdformat = "yyyy/mm/dd HH:MM:ss"

const formatCreated = t => dateformat(t, "yyyy/mm/dd HH:MM")

const AdminOffersEdit = props => {
    const params = useParams()
    const { offerById, isLoading, save, isSaving, fix, cancel } = useAdminOffers()
    const offer = offerById(params.offerId)
    const [item, setItem] = useState(offer)
    useEffect(() => {
        setItem(offer)
    }, [offer])
    const dateList = useMemo(() => {
        const list = []
        if (!item) {
            return list
        }
        for (var i = -10; i <= 10; i++) {
            const d = new Date(item.year, item.month - 1, item.day + i, 0, 0, 0, 0)
            list.push(d)
        }
        return list
    }, [item])
    const timeList = useMemo(() => {
        const list = []
        if (!item) {
            return list
        }
        const tMin = new Date(2020, 0, 1, 7, 0, 0, 0)
        const tMax = new Date(2020, 0, 1, 18, 0, 0, 0)
        var i = 0
        while (true) {
            const t = new Date(2020, 0, 1, tMin.getHours(), tMin.getMinutes() + i * 15, 0, 0)
            if (t > tMax) {
                break
            }
            list.push(t)
            i++
        }
        return list
    }, [item])
    const { inputProps, radioProps, values, formChanged, setFormChanged } = useForm(item, {
        mapItemToFormValues: item => {
            if (!item) {
                return null
            }
            return {
                // date: dateformat(new Date(item.year, item.month-1, item.day, 0, 0, 0, 0), "yyyy/mm/dd"),
                // time: dateformat(new Date(item.year, item.month-1, item.day, item.startHour, item.startMinute, 0, 0), "HH:MM"),
                date: dateformat(new Date(item.year, item.month - 1, item.day, 0, 0, 0, 0), stdformat),
                time: dateformat(new Date(2020, 0, 1, item.startHour, item.startMinute, 0, 0), stdformat),
                basho: item.basho,
                bikou: item.bikou,
                section: item.section,
                grade: item.grade,
                task: item.task,
            }
        },
        onChange: (name, value) => {
            switch (name) {
                case 'date':
                    setItem({
                        ...item,
                        year: new Date(value).getFullYear(),
                        month: new Date(value).getMonth() + 1,
                        day: new Date(value).getDate(),
                    })
                    break
                case 'time':
                    setItem({
                        ...item,
                        startHour: new Date(value).getHours(),
                        startMinute: new Date(value).getMinutes(),
                    })
                    break
                default:
                    setItem({
                        ...item,
                        [name]: value,
                    })
            }
        }
    })
    const handleSubmit = useCallback(async evt => {
        evt.preventDefault()
        const { ok } = await save(item)
        if (ok) {
            setFormChanged(false)
            alert('保存しました')
        }
        return false
    }, [save, item, setFormChanged])
    const handleFix = useCallback(async evt => {
        evt.preventDefault()
        const { ok } = await fix(item.offerId)
        if (ok) {
            setFormChanged(false)
            alert('予定を確定しました')
        }
        return false
    }, [item, setFormChanged, fix])
    const handleCancel = useCallback(async evt => {
        evt.preventDefault()
        const { ok } = await cancel(item.offerId)
        if (ok) {
            setFormChanged(false)
            alert('取消ました')
        }
    }, [cancel, item, setFormChanged])
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    if (isLoading) {
        return <div>...</div>
    }
    if (!item) {
        return <div>...</div>
    }
    if (!values) {
        return <div>...</div>
    }
    return (
        <section className="contents">
            <div className="contents1000">
                <h2 className="adminTitle">申し込み内容詳細</h2>
                <div className="formBlock admin">
                    <div className="form clearfix">
                        <div className="leftBox">会社名</div>
                        <div className="rightBox"><span>{item.userCompanyName}</span></div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">担当者名</div>
                        <div className="rightBox"><span>{item.userName}</span></div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">電話番号</div>
                        <div className="rightBox"><span>{item.userTel}</span></div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">メールアドレス</div>
                        <div className="rightBox"><span>{item.userEmail}</span></div>
                    </div>
                </div>
                <div className="formBlock admin">
                    <div className="form clearfix">
                        <div className="leftBox">申し込み日</div>
                        <div className="rightBox"><span>{formatCreated(item.createdAt)}</span></div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">配車日</div>
                        <div className="rightBox">
                            <select className="" {...inputProps('date')}>
                                {console.log({ dateList })}
                                {dateList.map(d => {
                                    console.log({ d }, d)
                                    return <option value={dateformat(d, stdformat)}>{dateformat(d, "yyyy/mm/dd")}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">時間帯</div>
                        <div className="rightBox">
                            <select className="" {...inputProps('section')}>
                                <option value="gozen">午前</option>
                                <option value="gogo">午後</option>
                                <option value="mitei">時間指定なし</option>
                                <option value="syujitu">終日チャーター</option>
                            </select>
                        </div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">車体</div>
                        <div className="rightBox">
                            <select className="" {...inputProps('grade')}>
                                <option value="3t">3トンユニック車</option>
                                <option value="7t">7トンユニック車</option>
                                <option value="13t">15トンユニック車</option>
                                <option value="13th">15トン平車</option>
                            </select>
                        </div>
                    </div>

                    {['gogo', 'gozen'].includes(item.section) && (
                        <div className="form clearfix">
                            <div className="leftBox">着指定時間</div>
                            <div className="rightBox">
                                <select className="" {...inputProps('time')}>
                                    {timeList.map(t => (
                                        <option value={dateformat(t, stdformat)}>{dateformat(t, "HH:MM")}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}

                    {item.section !== 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">業務内容</div>
                            <div className="rightBox">
                                <div className="md-radio md-radio-inline">
                                    <input id="13" type="radio" {...radioProps('task', 'nouhin')} />
                                    <label for="13">納品</label>
                                </div>
                                <div className="md-radio md-radio-inline">
                                    <input id="14" type="radio" {...radioProps('task', 'hikitori')} />
                                    <label for="14">引き取り</label>
                                </div>
                            </div>
                        </div>
                    )}
                    {item.section === 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">前積み</div>
                            <div className="rightBox">
                                <div className="md-radio md-radio-inline">
                                    <input id="13" type="radio" {...radioProps('task', 'maezumi_ari')} />
                                    <label for="13">あり</label>
                                </div>
                                <div className="md-radio md-radio-inline">
                                    <input id="14" type="radio" {...radioProps('task', 'maezumi_nashi')} />
                                    <label for="14">なし</label>
                                </div>
                                <div className="md-radio md-radio-inline">
                                    <input id="15" type="radio" {...radioProps('task', 'maezumi_mitei')} />
                                    <label for="15">未定</label>
                                </div>
                            </div>
                        </div>
                    )}

                    {item.section !== 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">場所</div>
                            <div className="rightBox">
                                <input className="" {...inputProps('basho', 'text')} />
                            </div>
                        </div>
                    )}

                    {item.section !== 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">備考</div>
                            <div className="rightBox">
                                <textarea {...inputProps('bikou')} />
                            </div>
                        </div>
                    )}
                    {item.section === 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">業務内容</div>
                            <div className="rightBox">
                                <textarea {...inputProps('bikou')} />
                            </div>
                        </div>
                    )}
                    <input type="submit" className="defaultBtn selectBtn yoyakuSaveBtn" value="保存" onClick={handleSubmit} disabled={!formChanged || isSaving} />
                    <input type="submit" className="defaultBtn selectBtn yoyakuKakuBtn" value="予約確定(メール・カレンダー送信)" onClick={handleFix} disabled={formChanged || offer.status !== 'new' || isSaving} />
                    <input type="submit" className="defaultBtn selectBtn yoyakuKeshiBtn" value="予約取消" onClick={handleCancel} disabled={offer.status !== 'new' || isSaving} />

                </div>
                <Link to="/admin/offers" className="backBtn">申し込み一覧に戻る</Link>
            </div>
        </section>

    )
}

export default AdminOffersEdit
