import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import OfferProgress from './OfferProgress'
import { useOfferForm } from './offerForm'
import { useOfferUser } from './offerUserHooks'
import { useCalender } from './calender'

const OfferPage1 = props => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const {offer, setOffer, setPage} = useOfferForm()
    const {calender, fetchCalender} = useCalender()
    console.log({calender})
    const {authCode} = useOfferUser()

    useEffect(() => {
        fetchCalender()
    }, [fetchCalender])

    const handleClick = useCallback((year, month, day, calDate) => () => {
        if (!calDate.enable) {
            return
        }
        setOffer({
            ...offer,
            year,
            month,
            day,
        })
        setPage(2)
    }, [offer, setOffer, setPage])

    const renderCalenderDate = useCallback(params => {
        const [isPadding, year, month, day, , calDate] = params
        if (isPadding) {
            return (
                <div className="padding">
                    <span></span>
                </div>
            )
        }
        const classNames = []
        if (!calDate.enable) {
            classNames.push('finish')
        }
        if (calDate.today) {
            classNames.push('today')
        }
        const className = classNames.join(' ')
        return (
            <div key={`${year}/${month}/${day}`} className={className} onClick={handleClick(year, month, day, calDate)}>
                <span>{day}</span>
            </div>
        )
    }, [handleClick])

    const renderCalender = (year, month, now, dayList) => {
        const firstDate = new Date(year, month-1, 1, 0, 0, 0, 0)
        const firstDateWeek = firstDate.getDay()
        const nPadding = (firstDateWeek + 6) % 7

        const days = []
        for (let i = nPadding; i > 0; i--) {
            const d = new Date(year, firstDate.getMonth()+1, 1 - i, 0, 0, 0, 0)
            days.push([true, year, month, d.getDate(), d.getDay()])
        }
        const lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth()+1, 0, 0, 0, 0, 0)
        const lastDay = lastDate.getDate()
        const lastDayWeek = lastDate.getDay()
        const nLastPadding = (7 - lastDayWeek) % 7
        console.log({w: lastDayWeek, lastDay, lastDate})

        for (let i = 1; i <= lastDay; i++) {
            const d = new Date(year, firstDate.getMonth(), i, 0, 0, 0, 0)
            const calDate = dayList[i-1]
            days.push([false, year, month, d.getDate(), d.getDay(), calDate])
        }

        for (let i = 0; i < nLastPadding; i++) {
            const d = new Date(year, month, lastDay + i, 0, 0, 0, 0)
            days.push([true, year, month, d.getDate(), d.getDay()])
        }

        return (
            <div className="calender">
                <div className="title defaultBtn">{year}年{month}月</div>
                <div className="calenderContents">
                    <div className="dow">
                        <div>月</div>
                        <div>火</div>
                        <div>水</div>
                        <div>木</div>
                        <div>金</div>
                        <div className="satTitle">土</div>
                        <div className="sunTitle">日</div>
                    </div>
                    <div className="dayList">
                        {days.map((item, i) => (
                            renderCalenderDate(item)
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <section className="contents">
            <div className="contents1000">
                <OfferProgress progress={1} />

                <p className="mainComment">
                配車のご希望日を選択してください。
		        </p>


                {calender.map(cal => renderCalender(cal.year, cal.month, null, cal.days))}

                <a href={`/offer/ac=${authCode}`} className="backBtn">トップに戻る</a>
            </div>
        </section>
    )
}

export default OfferPage1

