import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { useOffers } from './offersHook'

const dateformat = require('dateformat')
const stdformat = "yyyy/mm/dd HH:MM:ss"

const formatCreated = t => dateformat(t, "yyyy/mm/dd HH:MM")

const OffersDetail = props => {
    const { isLoading, selectedItem: item, setSelectedItem  } = useOffers()
    console.log({item})
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleClickBack = useCallback(() => {
        setSelectedItem(null)
    }, [setSelectedItem])
    if (isLoading) {
        return <div>...</div>
    }
    if (!item) {
        return <div>...</div>
    }
    return (
        <section className="contents">
            <div className="contents1000">
                <h2 className="adminTitle">申し込み内容詳細</h2>
                <div className="formBlock admin">
                    <div className="form clearfix">
                        <div className="leftBox">申し込み日</div>
                        <div className="rightBox"><span>{formatCreated(item.createdAt)}</span></div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">配車日</div>
                        <div className="rightBox">
                            <span>{dateformat(new Date(item.year, item.month - 1, item.day, 0, 0, 0, 0), "yyyy/mm/dd")}</span>
                        </div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">時間帯</div>
                        <div className="rightBox">
                            {formats.sectionWithTime(item)}
                        </div>
                    </div>
                    <div className="form clearfix">
                        <div className="leftBox">車体</div>
                        <div className="rightBox">
                            <span>{formats[item.grade]}</span>
                        </div>
                    </div>

                    {['gogo', 'gozen'].includes(item.section) && (
                        <div className="form clearfix">
                            <div className="leftBox">着指定時間</div>
                            <div className="rightBox">
                                <span>{dateformat(new Date(2020, 0, 1, item.startHour, item.startMinute, 0, 0), stdformat)}</span>
                            </div>
                        </div>
                    )}

                    {item.section !== 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">業務内容</div>
                            <div className="rightBox">
                                {formats[item.task]}
                            </div>
                        </div>
                    )}
                    {item.section === 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">前積み</div>
                            <div className="rightBox">
                                {formats[item.task]}
                            </div>
                        </div>
                    )}

                    {item.section !== 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">場所</div>
                            <div className="rightBox">
                                <span>{item.basho}</span>
                            </div>
                        </div>
                    )}

                    {item.section !== 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">備考</div>
                            <div className="rightBox">
                                {item.bikou}
                            </div>
                        </div>
                    )}
                    {item.section === 'syujitu' && (
                        <div className="form clearfix">
                            <div className="leftBox">業務内容</div>
                            <div className="rightBox">
                                {item.bikou}
                            </div>
                        </div>
                    )}
                </div>
                <button className="backBtn" onClick={handleClickBack}>申し込み一覧に戻る</button>
            </div>
        </section>

    )
}

export default OffersDetail

const formats = {
    status: {
        new: '未確',
        fix: '確定',
        cancel: '取消',
    },
    gozen: '午前',
    gogo: '午後',
    syujitu: 'チャーター',
    mitei: '未定',
    '3t': '3トン',
    '7t': '7トン',
    '13t': '15トン',
    '13th': '15平',
    nouhin: '納品',
    hikitori: '引き取り',
    nouhin_hikitori: '納品/引き取り',
    maezumi_ari: '前積あり',
    maezumi_nashi: '前積なし',
    maezumi_mitei: '前積未定',
    sectionWithTime: offer => {
        const t = dateformat(new Date(2020, 0, 1, offer.startHour, offer.startMinute, 0, 0), "HH:MM")
        switch (offer.section) {
            case 'gozen':
                return `【前】${t}`
            case 'gogo':
                return `【後】${t}`
            case 'mitei':
                return `時間未定`
            case 'syujitu':
                return `チャーター`
            default:
                return ``
        }
    },
}