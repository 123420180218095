import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import * as api from './userApi'

const context = createContext()

export const CalenderProvider = props => {
    const [calender, setCalender] = useState()
    const fetchCalender = useCallback(async () => {
        const {ok, calender: cal} = await api.getCalender()
        if (!ok) {
            return
        }
        setCalender(cal)
    }, [])
    useEffect(() => {
        fetchCalender()
    }, [fetchCalender])
    const ctx = {
        calender,
        fetchCalender,
    }
    return (
        <context.Provider value={ctx}>
            {calender && props.children}
            {!calender && <div>...</div>}
        </context.Provider>
    )
}

export const useCalender = () => {
    return useContext(context)
}
