import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import OfferProgress from './OfferProgress'
import { useOfferForm } from './offerForm'
import { Link } from 'react-router-dom'
import { useCalender } from './calender'

const OfferPage2 = props => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { offer, setOffer, setPage } = useOfferForm()
    const { calender, fetchCalender } = useCalender()

    useEffect(() => {
        fetchCalender()
    }, [fetchCalender])

    const cal = calender.reduce((o, cur) => [...o, ...cur.days], [])
        .find(({ year, month, day }) => (year === offer.year && month === offer.month && day === offer.day))
    const statusList = cal ? cal.statusList : null
    console.log({ offer, calender, statusList })

    const handleClick = useCallback((section, grade, statusIdx) => () => {
        if (statusList == null) {
            return false
        }
        if (statusList[statusIdx].status === "unable") {
            return false
        }
        let startHour, startMinute, task
        switch (section) {
            case 'gozen':
                startHour = 7
                startMinute = 0
                task = 'nouhin'
                break
            case 'gogo':
                startHour = 12
                startMinute = 0
                task = 'nouhin'
                break
            case 'syujitu':
                startHour = 0
                startMinute = 0
                task = "maezumi_ari"
                break
            case 'mitei':
                startHour = 0
                startMinute = 0
                task = "nouhin"
                break
            default:
        }
        setOffer({
            ...offer, section, grade,
            startHour,
            startMinute,
            basho: '',
            bikou: '',
            task,
        })
        setPage(3)
    }, [setOffer, offer, statusList, setPage])

    const handleClickBack = useCallback(evt => {
        evt.preventDefault()
        setPage(1)
        return false
    }, [setPage])

    return (
        <section className="contents">
            <div className="contents1000">
                <OfferProgress progress={2} />
                <p className="mainComment">
                時間帯と車体を選択してください。 <br />
「△」は調整が必要な時間帯となるため、お申込み完了後担当者より折り返しご連絡いたします。
		        </p>

                <div className="selectTable">
                    <table>
                        <tr>
                            <th></th>
                            <th>3tユニック車</th>
                            <th>7tユニック車</th>
                            <th>15tユニック車</th>
                            <th>15t平車</th>
                        </tr>
                        <tr>
                            <th>午前</th>
                            <td onClick={handleClick('gozen', '3t', 0)} className={statusClassName(0, statusList)}>{renderStatus(0, statusList)}</td>
                            <td onClick={handleClick('gozen', '7t', 1)} className={statusClassName(1, statusList)}>{renderStatus(1, statusList)}</td>
                            <td onClick={handleClick('gozen', '13t', 2)} className={statusClassName(2, statusList)}>{renderStatus(2, statusList)}</td>
                            <td onClick={handleClick('gozen', '13th', 3)} className={statusClassName(3, statusList)}>{renderStatus(3, statusList)}</td>
                        </tr>
                        <tr>
                            <th>午後</th>
                            <td onClick={handleClick('gogo', '3t', 4)} className={statusClassName(4, statusList)}>{renderStatus(4, statusList)}</td>
                            <td onClick={handleClick('gogo', '7t', 5)} className={statusClassName(5, statusList)}>{renderStatus(5, statusList)}</td>
                            <td onClick={handleClick('gogo', '13t', 6)} className={statusClassName(6, statusList)}>{renderStatus(6, statusList)}</td>
                            <td onClick={handleClick('gogo', '13th', 7)} className={statusClassName(7, statusList)}>{renderStatus(7, statusList)}</td>
                        </tr>
                        <tr>
                            <th>時間指定なし</th>
                            <td onClick={handleClick('mitei', '3t', 8)} className={statusClassName(8, statusList)}>{renderStatus(8, statusList)}</td>
                            <td onClick={handleClick('mitei', '7t', 9)} className={statusClassName(9, statusList)}>{renderStatus(9, statusList)}</td>
                            <td onClick={handleClick('mitei', '13t', 10)} className={statusClassName(10, statusList)}>{renderStatus(10, statusList)}</td>
                            <td onClick={handleClick('mitei', '13th', 11)} className={statusClassName(11, statusList)}>{renderStatus(11, statusList)}</td>
                        </tr>
                        <tr>
                            <th>終日チャーター</th>
                            <td onClick={handleClick('syujitu', '3t', 12)} className={statusClassName(12, statusList)}>{renderStatus(12, statusList)}</td>
                            <td onClick={handleClick('syujitu', '7t', 13)} className={statusClassName(13, statusList)}>{renderStatus(13, statusList)}</td>
                            <td onClick={handleClick('syujitu', '13t', 14)} className={statusClassName(14, statusList)}>{renderStatus(14, statusList)}</td>
                            <td onClick={handleClick('syujitu', '13th', 15)} className={statusClassName(15, statusList)}>{renderStatus(15, statusList)}</td>
                        </tr>
                    </table>


                </div>
                <Link onClick={handleClickBack} className="backBtn">日にちの選択に戻る</Link>
            </div>
        </section>
    )
}

export default OfferPage2

const renderStatus = (idx, statusList) => {
    if (statusList == null) {
        return '×'
    }
    const st = statusList[idx]
    switch (st.status) {
        case 'enable':
            return '〇'
        case 'maybe':
            return '△'
        case 'unable':
            return '×'
        default:
            return ''
    }
}

const statusClassName = (idx, statusList) => {
    if (statusList == null) {
        return ''
    }
    if (statusList[idx].status === "unable") {
        return 'finish'
    }
    return ''
}
