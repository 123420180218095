import React, { createContext, useState, useReducer, useEffect, useCallback, useContext } from 'react'
import * as api from './adminApi'
import { useOffers } from './offersHook'

const context = createContext()

const reducer = (state, action) => {
    switch (action.type) {
        case 'setResult':
            return action.result
        case 'replace':
            console.log({state})
            return {
                ...state,
                items: state.items.map(v => {
                    if (v.offerId === action.item.offerId) {
                        return action.item
                    }
                    return v
                }),
            }
        default:
            return state
    }
}

export const Provider = props => {
    const { children } = props
    const [offersResult, dispatch] = useReducer(reducer, initialOffersResult)
    console.log({offersResult})
    const {items: offers} = offersResult
    const {total: offersTotal} = offersResult
    console.log({offersTotal})
    const [fetching, setFetching] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [searchOpts, setSearchOpts] = useState(initialSearchOpts)
    const [users, setUsers] = useState(null)

    const fetchUsersAsync = useCallback(async () => {
        const {ok, items} = await api.getActiveUsers()
        if (!ok) {
            return
        }
        setUsers(items)
    }, [])

    useEffect(() => {
        fetchUsersAsync()
    }, [fetchUsersAsync])

    const fetchOffersAsync = useCallback(async () => {
        try {
            setFetching(true)
            const { ok, result } = await api.getOffers(searchOpts)
            if (ok) {
                dispatch({ type: 'setResult', result })
            }
        } catch (error) {
            console.log({ error })
        } finally {
            setFetching(false)
        }
    }, [searchOpts])
    useEffect(() => {
        fetchOffersAsync()
    }, [fetchOffersAsync])
    const offerById = useCallback(offerId => {
        if (fetching) {
            return null
        }
        return offers.find(o => o.offerId === offerId)
    }, [offers, fetching])
    const save = useCallback(async offer => {
        try {
            if (isSaving) {
                return { ok: false }
            }
            setIsSaving(true)
            const { ok, item } = await api.putOffer(offer)
            if (ok) {
                dispatch({ type: 'replace', item })
                return { ok: true, item }
            }
            return { ok: false }
        } finally {
            setIsSaving(false)
        }
    }, [isSaving])
    const fix = useCallback(async offerId => {
        try {
            if (isSaving) {
                return { ok: false }
            }
            setIsSaving(true)
            const { ok, item } = await api.fixOffer(offerId)
            if (ok) {
                dispatch({ type: 'replace', item })
                return { ok: true, item }
            }
            return { ok: false }
        } finally {
            setIsSaving(false)
        }
    }, [isSaving])
    const cancel = useCallback(async offerId => {
        try {
            setIsSaving(true)
            if (isSaving) {
                return { ok: false }
            }
            const v = offerById(offerId)
            if (!v) {
                return { ok: false }
            }
            v.status = 'cancel'
            const { ok, item } = await api.putOffer(v)
            if (ok) {
                dispatch({ type: 'replace', item })
                return { ok: true, item }
            }
            return { ok: false }
        } finally {
            setIsSaving(false)
        }

    }, [offerById, isSaving])
    const switchType = useCallback(typ => {
        setSearchOpts({
            ...initialSearchOpts,
            typ,
        })
    }, [])
    const setPage = useCallback(page => {
        setSearchOpts(cur => ({
            ...cur,
            page,
        }))
    }, [])
    const setCompanyName = useCallback(companyName => {
        setSearchOpts(cur => ({
            ...initialSearchOpts,
            typ: cur.typ,
            companyName,
        }))
    }, [])
    const setOrderBy = useCallback(orderBy => {
        setSearchOpts(cur => ({
            ...cur,
            orderBy,
            page: 0,
        }))
    }, [])
    const ctx = {
        isLoading: fetching,
        offers,
        offerById,
        offersTotal,
        save,
        isSaving,
        fix,
        cancel,
        searchOpts,
        typ: searchOpts.typ,
        switchType,
        setPage,
        setCompanyName,
        users,
        setOrderBy,
    }
    return (
        <context.Provider value={ctx}>
            {children}
        </context.Provider>
    )
}

export const useAdminOffers = () => {
    return useContext(context)
}

const initialOffersResult = {
    items: [],
    total: 0,
}

const initialSearchOpts = {
    typ: "std",
    page: 1,
    perPage: 100,
    companyName: "",
    orderBy: 'createdAt',
}