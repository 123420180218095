import React, { createContext, useState, useEffect, useCallback, useContext } from 'react'
import * as api from './userApi'

const context = createContext()

export const Provider = props => {
    const {authCode, children} = props
    const [user, setUser] = useState(null)
    const [fetching, setFetching] = useState(true)

    const fetchUserAsync = useCallback(async authCode => {
        const {ok, item} = await api.getSelfByAuthCode(authCode)
        if (!ok) {
            return
        }
        setUser(item)
        setFetching(false)
    }, [])

    useEffect(() => {
        console.log('useEffect', {authCode})
        if (!fetching) {
            return
        }
        fetchUserAsync(authCode)
    }, [fetching, authCode, fetchUserAsync])

    const ctx = {
        user,
        authCode,
        isLoading: fetching,
    }
    return (
        <context.Provider value={ctx}>
            {children}
        </context.Provider>
    )
}

export const useOfferUser = () => {
    return useContext(context)
}
