import React, { createContext, useContext, useMemo, useState } from 'react'
import { useForm } from './form'

const dateformat = require('dateformat')
const stdformat = "yyyy/mm/dd HH:MM:ss"

const context = createContext()

export const Provider = props => {
    const { children } = props
    const [page, setPage] = useState(1)
    const [item, setItem] = useState({
        year: 2020,
        month: 1,
        day: 1,
        startHour: 0,
        startMinute: 0,
        basho: '',
        bikou: '',
        section: '',
        grade: '',
        task: '',
    })
    const form = useForm(item, {
        mapItemToFormValues: item => {
            if (!item) {
                return null
            }
            return {
                date: dateformat(new Date(item.year, item.month - 1, item.day, 0, 0, 0, 0), stdformat),
                time: dateformat(new Date(2020, 0, 1, item.startHour, item.startMinute, 0, 0), stdformat),
                startHour: `${item.startHour}`,
                startMinute: `${item.startMinute}`,
                basho: item.basho,
                bikou: item.bikou,
                section: item.section,
                grade: item.grade,
                task: item.task,
            }
        },
        onChange: (name, value) => {
            switch (name) {
                case 'date':
                    setItem({
                        ...item,
                        year: new Date(value).getFullYear(),
                        month: new Date(value).getMonth() + 1,
                        day: new Date(value).getDate(),
                    })
                    break
                case 'time':
                    console.log('time', {value})
                    setItem({
                        ...item,
                        startHour: new Date(value).getHours(),
                        startMinute: new Date(value).getMinutes(),
                    })
                    break
                default:
                    setItem({
                        ...item,
                        [name]: value
                    })
            }
        }
    })
    const ctx = useMemo(() => {
        return {
            ...form,
            offer: item,
            setOffer: setItem,
            page,
            setPage,
        }
    }, [form, item, page])
    return (
        <context.Provider value={ctx}>
            {children}
        </context.Provider>
    )
}

export const useOfferForm = () => {
    return useContext(context)
}
