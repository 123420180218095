import React, { useCallback } from 'react'
import { useOffers } from './offersHook'
import { Link } from 'react-router-dom'
import { useOfferUser } from './offerUserHooks'

const dateformat = require('dateformat')
const formatCreatedAt = t => dateformat(t, "mm/dd HH:MM")

const formats = {
    status: {
        new: '未確',
        fix: '確定',
        cancel: '取消',
    },
    gozen: '午前',
    gogo: '午後',
    syujitu: 'チャーター',
    mitei: '未定',
    '3t': '3トン',
    '7t': '7トン',
    '13t': '15トン',
    '13th': '15平',
    nouhin: '納品',
    hikitori: '引き取り',
    nouhin_hikitori: '納品/引き取り',
    maezumi_ari: '前積あり',
    maezumi_nashi: '前積なし',
    maezumi_mitei: '前積未定',
    sectionWithTime: offer => {
        const t = dateformat(new Date(2020, 0, 1, offer.startHour, offer.startMinute, 0, 0), "HH:MM")
        switch (offer.section) {
            case 'gozen':
                return `【前】${t}`
            case 'gogo':
                return `【後】${t}`
            case 'mitei':
                return `時間未定`
            case 'syujitu':
                return `チャーター`
            default:
                return ``
        }
    },
}

const formatReserveDate = offer => {
    const d = new Date(offer.year, offer.month - 1, offer.day, offer.startHour, offer.startMinute, 0, 0)
    const kweeks = ['日', '月', '火', '水', '木', '金', '土']
    return dateformat(d, "yyyy/mm/dd") + `(${kweeks[d.getDay()]})`
}

const OffersTable = () => {
    const { authCode } = useOfferUser()
    const { isLoading, offers, offersTotal: total, searchOpts, setPage, setSelectedItem, setOrderBy } = useOffers()
    const handleClickRow = useCallback(item => () => {
        setSelectedItem(item)
    }, [setSelectedItem])
    const { page, perPage, orderBy } = searchOpts
    const pageMax = Math.ceil(total / perPage)

    const handleClickPage = useCallback(page => () => {
        if (isLoading) {
            return
        }
        setPage(page)
    }, [isLoading, setPage])

    const handleClickOrderBy = orderBy => () => {
        setOrderBy(orderBy)
    }

    const renderPages = useCallback(() => {
        const a = []
        if (page > 1) {
            a.push(
                <div key="first" className="pg-first" onClick={handleClickPage(1)}></div>
            )
            a.push(
                <div key="prev" className="pg-prev" onClick={handleClickPage(page - 1)}></div>
            )
        }
        if (page < pageMax) {
            a.push(
                <div key="next" className="pg-next" onClick={handleClickPage(page + 1)}></div>
            )
            a.push(
                <div key="last" className="pg-last" onClick={handleClickPage(pageMax)}></div>
            )
        }
        return (
            <div className="pager">{a}</div>
        )
    }, [handleClickPage, page, pageMax])

    return (
        <section className="contents">

            <div className="contents1000">
                <h2 className="adminTitle">申し込み一覧</h2>

                <a className="btn-csv-download" href={`/api/v2/ac=${authCode}/offers/csv`} download>CSVダウンロード</a>
                <div class="pgTotal">全{total}件</div>
                {renderPages()}

                <div className="slideTableBox">
                    <table className="adminTable">
                        <thead>
                            <tr>
                                <th>状況</th>
                                <th>
                                    {orderBy === 'createdAt' ?
                                        (
                                            <>申込日</>
                                        ) : (
                                            <span className="orderBy" onClick={handleClickOrderBy('createdAt')}>申込日</span>
                                        )
                                    }
                                </th>
                                <th>
                                    {orderBy === 'reserveDate' ?
                                        (
                                            <>予約日</>
                                        ) : (
                                            <span className="orderBy" onClick={handleClickOrderBy('reserveDate')}>予約日</span>
                                        )
                                    }
                                </th>
                                <th>時間</th>
                                <th>車体</th>
                                <th>業務</th>
                                <th>場所</th>
                            </tr>
                        </thead>
                        <tbody>
                            {offers.map(item => (
                                <tr key={item.offerId} onClick={handleClickRow(item)}>
                                    <td>
                                        {item.status === 'cancel' && (
                                            <span className="cancel">取消</span>
                                        )}
                                        {item.status === 'new' && (
                                            <span className="mitei">未確</span>
                                        )}
                                        {item.status === 'fix' && (
                                            <span className="kakutei">確定</span>
                                        )}
                                    </td>
                                    <td>{formatCreatedAt(item.createdAt)}</td>
                                    <td>{formatReserveDate(item)}</td>
                                    <td>{formats.sectionWithTime(item)}</td>
                                    <td>{formats[item.grade]}</td>
                                    <td>{formats[item.task]}</td>
                                    <td>{item.basho}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Link to={`/offer/ac=${authCode}`} className="backBtn">配車予約トップに戻る</Link>
            </div>
        </section>
    )
}

export default OffersTable

