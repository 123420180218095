import { useCallback, useState } from 'react'

export const useForm = (item, opts) => {
    const {mapItemToFormValues, onChange} = opts
    const values = mapItemToFormValues(item)
    const [formChanged, setFormChanged] = useState(false)
    const inputProps = useCallback((name, type) => {
        return {
            name,
            type,
            onChange: evt => {
                console.log('onChange', {name})
                setFormChanged(true)
                onChange(name, evt.target.value, values)
            },
            value: values[name],
        }
    }, [values, onChange])
    const radioProps = useCallback((name, value) => {
        return {
            name,
            onChange: evt => {
                if (evt.target.checked) {
                    setFormChanged(true)
                    onChange(name, evt.target.value, values)
                }
            },
            value,
            checked: value === values[name]
        }
    }, [values, onChange])
    return {
        values,
        inputProps,
        radioProps,
        formChanged,
        setFormChanged,
    }
}
