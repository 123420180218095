import React from 'react'
import OffersDetail from './OffersDetail'
import { Provider, useOffers } from './offersHook'
import OffersTable from './OffersTable'

const Offers = () => {
    return (
        <Provider>
            <Switch />
        </Provider>
    )
}

const Switch = () => {
    const { selectedItem } = useOffers()
    if (selectedItem) {
        return <OffersDetail />
    }
    return <OffersTable />
}

export default Offers