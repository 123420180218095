import React from 'react'

const OfferProgress = props => {
    const {progress} = props
    return (
        <div className="orderProgress">
            <ul className="progressBar clearfix">
                <li className={(progress === 1 && "select") || ''}>
                    <span>日にちの<br className="pcNone" />選択</span>
                    <div className="stepRight"></div>
                    <div className="stepRight2"></div>
                </li>
                <li className={(progress === 2 && 'select') || ''}>
                    <span>時間/車体<br className="pcNone" />の選択</span>
                    <div className="stepRight"></div>
                    <div className="stepRight2"></div>
                </li>
                <li className={(progress === 3 && 'select') || ''}>
                    <span>詳細情報<br className="pcNone" />の入力</span>
                    <div className="stepRight"></div>
                    <div className="stepRight2"></div>
                </li>
                <li className={(progress === 4 && 'select') || ''}>
                    <span>内容の<br className="pcNone" />確認</span>
                    <div className="stepRight"></div>
                    <div className="stepRight2"></div>
                </li>
                <li className={(progress === 5 && 'select') || ''}>
                    <span>お申込み<br className="pcNone" />完了</span>
                    <div className="stepRight pcNone"></div>
                </li>
            </ul>
        </div>

    )
}

export default OfferProgress