import React, { useCallback, useState } from 'react'
import { useForm } from './form'
import * as api from './adminApi'

const labelStyle = {
    display: 'block',
}

const AdminLogin = props => {

    const [item, setItem] = useState({email: '', password: ''})
    const {inputProps} = useForm(item, {
        mapItemToFormValues: item => ({
            ...item,
        }),
        onChange: (name, value) => setItem({...item, [name]: value})
    })
    const handleSubmit = useCallback(async evt => {
        evt.preventDefault()
        const {ok} = await api.login(item.email, item.password)
        if (!ok) {
            alert('Emailかパスワードに誤りがあります')
            return
        }
        window.location.href = "/admin/"
        return false
    }, [item])
    return (
        <form onSubmit={handleSubmit}>
            <label style={labelStyle}>
                <span>Email</span>
                <input {...inputProps('email', 'email')} />
            </label>
            <label style={labelStyle}>
                <span>パスワード</span>
                <input {...inputProps('password', 'password')} />
            </label>
            <div>
                <button type="submit">ログイン</button>
            </div>
        </form>
    )
}

export default AdminLogin