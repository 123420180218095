const {fetch} = require('whatwg-fetch')

export const getSelfByAuthCode = async authCode => {
    try {
        const resp = await fetch(`/api/v2/ac=${authCode}/self`)
        if (!resp.ok) {
            return
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false, error}
    }
}

export const postOffer = async (authCode, offer) => {
    try {
        const resp = await fetch(`/api/v2/ac=${authCode}/offers`, {
            method: 'post',
            body: JSON.stringify(offer),
        })
        if (!resp.ok) {
            return
        }
        return {ok: true}
    } catch(error) {
        return {ok: false, error}
    }
}

export const getOffers = async (authCode, opts) => {
    console.log({opts})
    try {
        const resp = await fetch(`/api/v2/ac=${authCode}/offers?page=${opts.page}&per-page=${opts.perPage}&order-by=${opts.orderBy}`)
        if (!resp.ok) {
            return {ok: false}
        }
        const result = await resp.json()
        return {ok: true, result}
    } catch(error) {
        return {ok: false, error}
    }
   
}

export const getCalender = async () => {
    try {
        const resp = await fetch('/api/v2/calender')
        if (!resp.ok) {
            return {ok: false}
        }
        const calender = await resp.json()
        return {ok: true, calender}
    } catch(error) {
        return {ok: false, error}
    }
}


export const getShakensho = async name => {
    try {
        const resp = await fetch(`/api/v2/shakensho/${name}`)
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false}
    }
}