const {fetch} = require('whatwg-fetch')

const queryString = require('query-string')

export const postUser = async user => {
    try {
        const resp = await fetch('/api/v2/admin/users', {
            method: 'POST',
            body: JSON.stringify(user),
        })
        if (!resp.ok) {
            return { ok: false }
        }
        const item = await resp.json()
        return { ok: true, item }
    } catch (error) {
        return { ok: false, error }
    }
}

export const putUser = async user => {
    try {
        const resp = await fetch(`/api/v2/admin/users/${user.userId}`, {
            method: 'PUT',
            body: JSON.stringify(user),
        })
        if (!resp.ok) {
            return { ok: false }
        }
        const item = await resp.json()
        return { ok: true, item }
    } catch(error) {
        return {ok: false, error}
    }
}

export const getActiveUsers = async () => {
    try {
        const resp = await fetch('/api/v2/admin/users')
        if (!resp.ok) {
            return { ok: false }
        }
        const items = await resp.json()
        return { ok: true, items }
    } catch (error) {
        return { ok: false, error }
    }
}

export const getOffers = async opts => {
    console.log({opts})
    try {
        const typ = opts.typ
        const resp = await fetch(`/api/v2/admin/offers/type=${typ}?page=${opts.page}&per-page=${opts.perPage}&company-name=${opts.companyName}&order-by=${opts.orderBy}`)
        if (!resp.ok) {
            return {ok: false}
        }
        const result = await resp.json()
        return {ok: true, result}
    } catch(error) {
        return {ok: false, error}
    }
   
}

export const putOffer = async offer => {
    try {
        const resp = await fetch(`/api/v2/admin/offers/${offer.offerId}`, {
            method: 'PUT',
            body: JSON.stringify(offer),
        })
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false, error}
    }
}

export const fixOffer = async offerId => {
    try {
        const resp = await fetch(`/api/v2/admin/offers/${offerId}/actions/fix`, {
            method: 'POST',
            body: '',
        })
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false}
    }
}

export const getCalender = async () => {
    try {
        const resp = await fetch(`/api/v2/admin/calender`)
        if (!resp.ok) {
            return {ok: false}
        }
        const items = await resp.json()
        return {ok: true, items}
    } catch(error) {
        return {ok: false}
    }
}

export const changeCalenderStatus = async (year, month, day, section, grade, status) => {
    try {
        const resp = await fetch(`/api/v2/admin/calender/${year}/${month}/${day}/${section}/${grade}/actions/change-status`, {
            method: 'POST',
            body: JSON.stringify({
                status,
            })
        })
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false}
    }
}

export const login = async (email, password) => {
    try {
        const resp = await fetch(`/api/v2/admin/sessions`, {
            method: 'POST',
            body: JSON.stringify({
                email, password,
            }),
        })
        if (!resp.ok) {
            return {ok: false}
        }
        return {ok: true}
    } catch(error) {
        return {ok: false}
    }
}

export const getCurrentSession = async () => {
    try {
        const resp = await fetch(`/api/v2/admin/sessions/current`)
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false}
    }
}