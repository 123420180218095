import React, { useCallback, useLayoutEffect, useMemo } from 'react'
import { useOfferForm } from './offerForm'
import { Link } from 'react-router-dom'
import OfferProgress from './OfferProgress'

const dateformat = require('dateformat')
const stdformat = "yyyy/mm/dd HH:MM:ss"

const OfferPage3 = props => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { inputProps, radioProps, offer, setPage } = useOfferForm()
    const handleSubmit = useCallback(evt => {
        evt.preventDefault()
        setPage(4)
        return false
    }, [setPage])
    const handleClickBack = useCallback(evt => {
        evt.preventDefault()
        setPage(2)
        return false
    }, [setPage])
    const timeList = useMemo(() => {
        const list = []
        if (!offer) {
            return list
        }
        let tMin
        let tMax
        switch (offer.section) {
            case 'gozen':
                tMin = new Date(2020, 0, 1, 7, 0, 0, 0)
                tMax = new Date(2020, 0, 1, 12, 0, 0, 0)
                break
            case 'gogo':
                tMin = new Date(2020, 0, 1, 12, 0, 0, 0)
                tMax = new Date(2020, 0, 1, 17, 0, 0, 0)
                break
            default:
                return list
        }
        /*
        const tMin = new Date(2020, 0, 1, 7, 0, 0, 0)
        const tMax = new Date(2020, 0, 1, 18, 0, 0, 0)
        */
        var i = 0
        while (true) {
            const t = new Date(2020, 0, 1, tMin.getHours(), tMin.getMinutes() + i * 15, 0, 0)
            if (t > tMax) {
                break
            }
            list.push(t)
            i++
        }
        return list
    }, [offer])
    return (
        <section className="contents">
            <div className="contents1000">
                <OfferProgress progress={3} />
                {['gogo', 'gozen', 'mitei'].includes(offer.section) && (
                    <p className="mainComment">
下記の必要事項のご入力をお願いいたします。 <br />
場所に関しては「現場名、通称、住所」等、どのような形式でも構いません。 <br />
                    </p>
                )}
                {!['gogo', 'gozen', 'mitei'].includes(offer.section) && (
                    <p className="mainComment">
                        下記の必要事項のご入力をお願いいたします。
                    </p>
                )}
                <form className="formBlock" onSubmit={handleSubmit}>
                    {offer.section !== 'syujitu' && (
                        <React.Fragment>
                            {['gogo', 'gozen'].includes(offer.section) && (
                                <div className="form clearfix">
                                    <div className="leftBox">着指定時間</div>
                                    <div className="rightBox">
                                        <select className="" {...inputProps('time')}>
                                            {timeList.map(t => (
                                                <option value={dateformat(t, stdformat)}>{dateformat(t, "HH:MM")}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="form clearfix">
                                <div className="leftBox">業務内容</div>
                                <div className="rightBox">
                                    <div className="md-radio md-radio-inline">
                                        <input id="3" type="radio" {...radioProps('task', 'nouhin')} />
                                        <label htmlFor="3">納品</label>
                                    </div>
                                    <div className="md-radio md-radio-inline">
                                        <input id="4" type="radio" {...radioProps('task', 'hikitori')} />
                                        <label htmlFor="4">引き取り</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form clearfix">
                                <div className="leftBox">場所</div>
                                <div className="rightBox">
                                    <input {...inputProps('basho', 'text')} className="" />
                                </div>
                            </div>

                            <div className="form clearfix">
                                <div className="leftBox">備考</div>
                                <div className="rightBox">
                                    <textarea {...inputProps('bikou')} />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    {offer.section === 'syujitu' && (
                        <>
                            <div className="form clearfix">
                                <div className="leftBox">前日積み</div>
                                <div className="rightBox">
                                    <div className="md-radio md-radio-inline">
                                        <input id="7" type="radio" {...radioProps('task', 'maezumi_ari')} />
                                        <label for="7">あり</label>
                                    </div>
                                    <div className="md-radio md-radio-inline">
                                        <input id="5" type="radio" {...radioProps('task', 'maezumi_nashi')} />
                                        <label for="5">なし</label>
                                    </div>
                                    <div className="md-radio md-radio-inline">
                                        <input id="6" type="radio" {...radioProps('task', 'maezumi_mitei')} />
                                        <label for="6">未定</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form clearfix">
                                <div className="leftBox">業務内容</div>
                                <div className="rightBox">
                                    <textarea {...inputProps('bikou')}></textarea>
                                </div>
                            </div>
                        </>
                    )}
                    <input type="submit" className="defaultBtn selectBtn" value="確認ページへ進む" />
                </form>
                <Link onClick={handleClickBack} className="backBtn">時間/車体の選択に戻る</Link>
            </div>
        </section>
    )
}

export default OfferPage3