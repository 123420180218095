import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AdminUsersTable from './AdminUsersTable'
import AdminUsersNew from './AdminUsersNew'
import { Provider, useAdminUsers } from './adminUsersHooks'

const AdminUsers = props => {
    return (
        <Provider>
            <Loading>
                <Switch>
                    <Route path="/admin/users" exact component={AdminUsersTable} />
                    <Route path="/admin/users/new" exact component={AdminUsersNew} />
                    <Route path="/admin/users/:userId" exact component={AdminUsersNew} />
                </Switch>
            </Loading>
        </Provider>
    )
}

export default AdminUsers

const Loading = props => {
    const { children } = props
    const { isLoading } = useAdminUsers()
    if (isLoading) {
        return <div>...</div>
    }
    return (
        <React.Fragment>{children}</React.Fragment>
    )
}
