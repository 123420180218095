import React, { createContext, useState, useReducer, useCallback, useEffect, useContext } from 'react'
import * as api from './adminApi'

const context = createContext()

const reducer = (state, action) => {
    switch (action.type) {
        case 'setItems':
            return action.items
        case 'add':
            return [action.item, ...state]
        case 'delete':
            return state.filter(v => v.userId !== action.item.userId)
        case 'replace':
            return state.map(v => v.userId === action.item.userId ? action.item : v)
        default:
            return state
    }
}

export const Provider = props => {
    console.log('Provider')
    const { children } = props
    const [users, dispatch] = useReducer(reducer, [])
    const [fetching, setFetching] = useState(true)
    const fetchUsersAsync = useCallback(async () => {
        const { ok, items, error } = await api.getActiveUsers()
        console.log('fetchUsersAsync', {ok, items, error})
        if (!ok) {
            return
        }
        dispatch({ type: 'setItems', items })
        setFetching(false)
    }, [])
    const addUser = useCallback(async item => {
        const {ok, item: item2} = await api.postUser(item)
        if (ok) {
            dispatch({type: 'add', item: item2})
            return {ok, item: item2}
        }
        return {ok: false}
    }, [])
    const saveUser = useCallback(async item => {
        const {ok, item: item2} = await api.putUser(item)
        if (ok) {
            dispatch({type: 'replace', item: item2})
            return {ok, item: item2}
        }
        return {ok: false}
    }, [])
    const deleteUser = useCallback(async item => {
        const {ok, item: item2} = await api.putUser({...item, status: 'inactive'})
        if (ok) {
            dispatch({type: 'delete', item: item2})
            return {ok, item: item2}
        }
        return {ok: false}
    }, [])
    useEffect(() => {
        if (!fetching) {
            return
        }
        fetchUsersAsync()
    }, [fetching, fetchUsersAsync])
    const reload = useCallback(() => {
        setFetching(true)
    }, [])
    const userById = useCallback(userId => {
        return users.find(v => v.userId === userId)
    }, [users])
    console.log({users})
    const ctx = {
        users,
        reload,
        addUser,
        saveUser,
        deleteUser,
        isLoading: fetching,
        userById
    }
    return (
        <context.Provider value={ctx}>
            {children}
        </context.Provider>
    )
}

export const useAdminUsers = () => {
    return useContext(context)
}