import React from 'react'
import { Provider } from './adminOffersHook'
import AdminOffersTable from './AdminOffersTable'
import { Switch, Route } from 'react-router-dom'
import AdminOffersEdit from './AdminOffersEdit'


const AdminOffers = props => {
    return (
        <Provider>
            <Switch>
                <Route exact path="/admin/offers" component={AdminOffersTable} />
                <Route exact path="/admin/offers/:offerId" component={AdminOffersEdit} />
            </Switch>
        </Provider>
    )
}

export default AdminOffers